import { CheckboxBase } from '@yoti/react-components/checkbox';
import React from 'react';
import styles from './checkboxList.module.scss';

export interface RadioButtonListProps<T> {
    list: { label: string; value: T }[];
    value?: T[];
    onChange: (element: T[]) => void;
    disabled?: boolean;
}

const CheckboxList = <T extends {}>(props: RadioButtonListProps<T>) => {
    const { list, value, onChange, disabled } = props;

    const valueIndex = (entry: T) => {
        if (!value) {
            return -1;
        }

        return value.indexOf(entry);
    };

    const handleChange = (entry: T) => {
        const i = valueIndex(entry);
        const newValue = value || [];
        if (i >= 0) {
            newValue.splice(i, 1);
        } else {
            newValue.push(entry);
        }
        onChange(newValue);
    };

    return (
        <div className={styles['checkbox-list']} data-qa="checkbox-list-container">
            {list.map((entry, i) => {
                return (
                    <CheckboxBase
                        className={styles['checkbox']}
                        disabled={disabled}
                        key={i}
                        checked={valueIndex(entry.value) >= 0}
                        onChange={() => handleChange(entry.value)}
                        label={entry.label}
                        errors={undefined}
                    />
                );
            })}
        </div>
    );
};

export default CheckboxList;
