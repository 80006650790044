import { LANGUAGE_CODE } from '@getyoti/react-face-capture';
import { Interval } from '../components/result/age-antispoofing/age-displayer/range-age-displayer/RangeAgeDisplayer';
import {
    AgeDisplayMode,
    AgeEstimationModelField,
    Endpoint,
    LevelOfAssuranceField,
    MetadataField,
    OperatorField,
    SecureMode,
} from '../models/prediction';
import { getArrayFromString } from '../utils/types';

/**
 * Check the different arguments to provide a valid configuration value for
 * numbers.
 *
 * @param envValue The value from the environment variable.
 * @param deploymentValue The deployment value from the script "start.sh".
 * @param defaultValue The default value in case the previous arguments are not
 * valid.
 * @returns The configuration value to be used as default in the deployment.
 */
function getNumberConfigValue(
    envValue: number | undefined,
    deploymentValue: number,
    defaultValue: number | undefined,
) {
    if (envValue !== undefined && !isNaN(envValue)) return envValue;
    if (deploymentValue != undefined && !isNaN(deploymentValue)) return deploymentValue;
    return defaultValue;
}

/**
 * Check the different arguments to provide a valid configuration value for
 * strings.
 *
 * @param envValue The value from the environment variable.
 * @param deploymentValue The deployment value from the script "start.sh".
 * @param defaultValue The default value in case the previous arguments are not
 * valid.
 * @returns The configuration value to be used as default in the deployment.
 */
function getStringConfigValue(
    envValue: string | undefined,
    deploymentValue: string,
    defaultValue: string | undefined,
) {
    if (envValue) return envValue;
    if (deploymentValue) return deploymentValue;
    return defaultValue;
}

export default {
    // In development environment use the REACT_BACKEND_URL env variable. In
    // production, use the ${BACKEND_URL} placeholder. It will be substituted
    // before running the application (See 'docker/start.sh' script).
    // Remember that env variables must have REACT_APP prefix.
    apiUrl: process.env.REACT_APP_BACKEND || 'https://${BACKEND_URL}/api',
    version: process.env.REACT_APP_VERSION || '',
    env: process.env.NODE_ENV,
    stdDevThreshold:
        parseFloat(
            process.env.REACT_APP_STD_DEV_THRESHOLD
                ? process.env.REACT_APP_STD_DEV_THRESHOLD
                : '${STD_DEV_THRESHOLD}',
        ) || 6.0, // Default stdDevThreshold provided by R&D team to determine when a predicted age is accurate.
    showFaceMatching:
        (process.env.REACT_APP_SHOW_FACE_MATCHING
            ? process.env.REACT_APP_SHOW_FACE_MATCHING
            : '${SHOW_FACE_MATCHING}') === 'true',
    // TODO: This is temporary. When the user role system will be done, we will delete this option.
    showStoreImagesOption:
        (process.env.REACT_APP_SHOW_STORE_IMAGES_OPTION
            ? process.env.REACT_APP_SHOW_STORE_IMAGES_OPTION
            : '${SHOW_STORE_IMAGES_OPTION}') === 'true',
    levelAssuranceValues: getArrayFromString(
        process.env.REACT_APP_LEVEL_ASSURANCE_VALUES
            ? process.env.REACT_APP_LEVEL_ASSURANCE_VALUES
            : '${LEVEL_ASSURANCE_VALUES}',
    ) as LevelOfAssuranceField[],
    consentCheckboxLabel:
        process.env.REACT_APP_CONSENT_CHECKBOX_LABEL || '${CONSENT_CHECKBOX_LABEL}',
    consentText1: process.env.REACT_APP_CONSENT_TEXT_1 || '${CONSENT_TEXT_1}',
    consentText2: process.env.REACT_APP_CONSENT_TEXT_2 || '${CONSENT_TEXT_2}',
    showConsent:
        (process.env.REACT_APP_SHOW_CONSENT
            ? process.env.REACT_APP_SHOW_CONSENT
            : '${SHOW_CONSENT}') === 'true',
    allowedRolesForEndpointsConfig: getArrayFromString(
        process.env.REACT_APP_ALLOWED_ROLES_FOR_ENDPOINTS_CONFIG ||
            '${ALLOWED_ROLES_FOR_ENDPOINTS_CONFIG}',
    ),
    allowedRolesForMetadataConfig: getArrayFromString(
        process.env.REACT_APP_ALLOWED_ROLES_FOR_METADATA_CONFIG ||
            '${ALLOWED_ROLES_FOR_METADATA_CONFIG}',
    ),
    allowedRolesForLevelOfAssuranceConfig: getArrayFromString(
        process.env.REACT_APP_ALLOWED_ROLES_FOR_LEVEL_OF_ASSURANCE_CONFIG ||
            '${ALLOWED_ROLES_FOR_LEVEL_OF_ASSURANCE_CONFIG}',
    ),
    allowedRolesForAgeEstimationModelConfig: getArrayFromString(
        process.env.REACT_APP_ALLOWED_ROLES_FOR_AGE_ESTIMATION_MODEL_CONFIG ||
            '${ALLOWED_ROLES_FOR_AGE_ESTIMATION_MODEL_CONFIG}',
    ),
    allowedRolesForOperatorConfig: getArrayFromString(
        process.env.REACT_APP_ALLOWED_ROLES_FOR_OPERATOR_CONFIG ||
            '${ALLOWED_ROLES_FOR_OPERATOR_CONFIG}',
    ),
    allowedRolesForThresholdConfig: getArrayFromString(
        process.env.REACT_APP_ALLOWED_ROLES_FOR_THRESHOLD_CONFIG ||
            '${ALLOWED_ROLES_FOR_THRESHOLD_CONFIG}',
    ),
    allowedRolesForSecureModeConfig: getArrayFromString(
        process.env.REACT_APP_ALLOWED_ROLES_FOR_SECURE_MODE_CONFIG ||
            '${ALLOWED_ROLES_FOR_SECURE_MODE_CONFIG}',
    ),
    allowedRolesForConfigWrapperConfig: getArrayFromString(
        process.env.REACT_APP_ALLOWED_ROLES_FOR_CONFIG_WRAPPER_CONFIG ||
            '${ALLOWED_ROLES_FOR_CONFIG_WRAPPER_CONFIG}',
    ),
    allowedRolesForAgeDisplayModeConfig: getArrayFromString(
        process.env.REACT_APP_ALLOWED_ROLES_FOR_AGE_DISPLAY_MODE_CONFIG ||
            '${ALLOWED_ROLES_FOR_AGE_DISPLAY_MODE_CONFIG}',
    ),
    allowedRolesForLanguageConfig: getArrayFromString(
        process.env.REACT_APP_ALLOWED_ROLES_FOR_LANGUAGE_CONFIG ||
            '${ALLOWED_ROLES_FOR_LANGUAGE_CONFIG}',
    ),
    defaultConfig: {
        endpoint: (process.env.REACT_APP_DEFAULT_ENDPOINTS_CONFIG ||
            '${DEFAULT_ENDPOINTS_CONFIG}') as Endpoint,
        levelOfAssurance: getStringConfigValue(
            process.env.REACT_APP_DEFAULT_LEVEL_OF_ASSURANCE_CONFIG,
            '${DEFAULT_LEVEL_OF_ASSURANCE_CONFIG}',
            undefined,
        ) as LevelOfAssuranceField,
        ageEstimationModel: getStringConfigValue(
            process.env.REACT_APP_DEFAULT_AGE_ESTIMATION_MODEL_CONFIG,
            '${DEFAULT_AGE_ESTIMATION_MODEL_CONFIG}',
            undefined,
        ) as AgeEstimationModelField,
        metadata: getStringConfigValue(
            process.env.REACT_APP_DEFAULT_METADATA_CONFIG,
            '${DEFAULT_METADATA_CONFIG}',
            undefined,
        ) as MetadataField,
        operator: getStringConfigValue(
            process.env.REACT_APP_DEFAULT_OPERATOR_CONFIG,
            '${DEFAULT_OPERATOR_CONFIG}',
            undefined,
        ) as OperatorField,
        threshold: getNumberConfigValue(
            parseInt(process.env.REACT_APP_DEFAULT_THRESHOLD_CONFIG || '', 10),
            parseInt('${DEFAULT_THRESHOLD_CONFIG}', 10),
            undefined,
        ),
        mode: (process.env.REACT_APP_DEFAULT_MODE_CONFIG || '${DEFAULT_MODE_CONFIG}') as SecureMode,
        ageDisplayMode: (process.env.REACT_APP_DEFAULT_AGE_DISPLAY_MODE_CONFIG ||
            '${DEFAULT_AGE_DISPLAY_MODE_CONFIG}') as AgeDisplayMode,
        ageDisplayInterval: Number(
            process.env.REACT_APP_AGE_DISPLAY_INTERVAL_CONFIG || '${AGE_DISPLAY_INTERVAL_CONFIG}',
        ) as Interval,
        language: (process.env.REACT_APP_DEFAULT_LANGUAGE_CONFIG ||
            '${DEFAULT_LANGUAGE_CONFIG}') as LANGUAGE_CODE,
    },
};
