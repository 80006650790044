import { ALERT_TYPES } from '@yoti/react-components/alert';
import { useContext, useEffect } from 'react';
import { useNotify } from '.';
import AccountService from '../api/account/account';
import EnvService from '../api/env';
import GlobalContext from '../globalContext';
import { Session } from '../models/account';
import Config from '../utils/config';

const accountService = new AccountService(Config.apiUrl);
const envService = new EnvService(Config.apiUrl);

function useCheckSession(anonymous?: boolean): { account?: Session; accountChecked: boolean } {
    const globalContext = useContext(GlobalContext);
    const notify = useNotify();
    const { set, account, accountChecked } = globalContext;

    useEffect(() => {
        if (!anonymous && !accountChecked) {
            accountService
                .checkSession()
                .then((account) => {
                    return envService.config().then((envCfg) => {
                        console.log('request', account, envCfg);
                        return {
                            account,
                            envCfg,
                        };
                    });
                })
                .then(({ account, envCfg }) => {
                    set((previous) => ({
                        ...previous,
                        account: account,
                        accountChecked: true,
                        envConfig: envCfg,
                    }));
                })
                .catch((e) => {
                    set((previous) => ({
                        ...previous,
                        account: undefined,
                        accountChecked: true,
                        envConfig: undefined,
                    }));
                    if (e && e.message) {
                        notify({
                            message: e.message,
                            type: ALERT_TYPES.DANGER,
                            icon: true,
                        });
                    }
                });
        }
    }, [accountChecked, anonymous, notify, set]);

    return { account, accountChecked };
}

export default useCheckSession;
