import { useEffect, useState } from 'react';
import { EndpointConfigClassType } from '../components/config/endpoints/endpointClasses';
import { DEFAULT_CONFIG } from '../components/scan-panel/models';
import { RoleCode } from '../models/account';
import { Sections, validRoleConfig } from '../models/prediction';
import { ConfigProps, isConfig } from './../components/config/ConfigPanel';
import { useLocalStorage } from './storage';

export function useConfigStorage(
    key: string,
    defaultConfig: ConfigProps,
    role?: RoleCode,
): [ConfigProps, (value: ConfigProps) => void] {
    if (!role) role = RoleCode.User;

    const [configuration, setConfiguration] = useLocalStorage(key, defaultConfig, isConfig);

    const [configWithClasses, setConfigWithClasses] = useState<ConfigProps>(
        Object.assign(new ConfigProps(), configuration),
    );

    const configDep = JSON.stringify(configuration);

    useEffect(() => {
        const configuration = JSON.parse(configDep) as ConfigProps;
        configuration.endpoints.forEach((endpoint, i, endpoints) => {
            const endpointClass = EndpointConfigClassType[endpoint.key];
            if (endpointClass) {
                endpoints[i].cfg = Object.assign(new endpointClass(), endpoint.cfg);
            }
        });

        configuration.selectedSecureMode = validRoleConfig(Sections.SecureMode, role)
            ? configuration.selectedSecureMode
            : DEFAULT_CONFIG.selectedSecureMode;
        configuration.selectedMetadataField = validRoleConfig(Sections.Metadata, role)
            ? configuration.selectedMetadataField
            : DEFAULT_CONFIG.selectedMetadataField;
        configuration.selectedLevelOfAssuranceField = validRoleConfig(
            Sections.LevelOfAssurance,
            role,
        )
            ? configuration.selectedLevelOfAssuranceField
            : DEFAULT_CONFIG.selectedLevelOfAssuranceField;
        configuration.selectedAgeEstimationModelField = validRoleConfig(
            Sections.AgeEstimationModel,
            role,
        )
            ? configuration.selectedAgeEstimationModelField
            : DEFAULT_CONFIG.selectedAgeEstimationModelField;
        configuration.selectedOperatorField = validRoleConfig(Sections.Operator, role)
            ? configuration.selectedOperatorField
            : DEFAULT_CONFIG.selectedOperatorField;
        configuration.selectedThresholdField = validRoleConfig(Sections.Threshold, role)
            ? configuration.selectedThresholdField
            : DEFAULT_CONFIG.selectedThresholdField;
        configuration.selectedLanguage = validRoleConfig(Sections.Language, role)
            ? configuration.selectedLanguage
            : DEFAULT_CONFIG.selectedLanguage;

        setConfigWithClasses({
            consent: configuration.consent,
            endpoints: configuration.endpoints,
            selectedAgeDisplayMode: configuration.selectedAgeDisplayMode,
            selectedAgeEstimationModelField: configuration.selectedAgeEstimationModelField,
            selectedDeceptionTypes: configuration.selectedDeceptionTypes,
            selectedEndpoint: configuration.selectedEndpoint,
            selectedExpectedBrightness: configuration.selectedExpectedBrightness,
            selectedExpectedLocation: configuration.selectedExpectedLocation,
            selectedExpectedResult: configuration.selectedExpectedResult,
            selectedLanguage: configuration.selectedLanguage,
            selectedLevelOfAssuranceField: configuration.selectedLevelOfAssuranceField,
            selectedMetadataField: configuration.selectedMetadataField,
            selectedOperatorField: configuration.selectedOperatorField,
            selectedSecureMode: configuration.selectedSecureMode,
            selectedThresholdField: configuration.selectedThresholdField,
        });
    }, [configDep, role, defaultConfig]);

    return [configWithClasses, setConfiguration];
}

export default useConfigStorage;
