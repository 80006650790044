import Button, { BUTTON_SIZES, BUTTON_VARIATIONS } from '@yoti/react-components/button';
import { CheckboxBase } from '@yoti/react-components/checkbox';
import React, { useState } from 'react';
import { useGlobalContext } from '../../globalContext';
import Config from '../../utils/config';
import { HeaderTab } from '../commons';
import styles from './screen.module.scss';

const Screen: React.FC = ({ children }) => {
    const { scanConfiguration, setScanConfiguration } = useGlobalContext();
    const consent = scanConfiguration?.consent;
    const [consentValue, setConsentValue] = useState(false);

    const allowConsent = () => {
        setConsentValue(false); // Reset the checkbox value in case the user come back to this screen.
        setScanConfiguration({ ...scanConfiguration, consent: true });
    };

    if (Config.showConsent && !consent) {
        return (
            <>
                <HeaderTab tabs={['Consent']} />
                <div className={styles['consent']}>
                    <p className={styles['consent__text']}>{Config.consentText1}</p>
                    <p className={styles['consent__text']}>{Config.consentText2}</p>
                    <br />
                    <div className={styles['consent__form']}>
                        <CheckboxBase
                            label={Config.consentCheckboxLabel}
                            onChange={() => {
                                setConsentValue(!consentValue);
                            }}
                            checked={consentValue}
                        />

                        <br />
                        <br />

                        <Button
                            variation={BUTTON_VARIATIONS.PRIMARY}
                            size={BUTTON_SIZES.SMALL}
                            onClick={allowConsent}
                            disabled={!consentValue}
                        >
                            Allow
                        </Button>
                    </div>
                </div>
            </>
        );
    }

    return <>{children}</>;
};

export default Screen;
