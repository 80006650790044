import { EnvConfig } from '../models/env';
import { ApiBase } from './base';

class EnvService extends ApiBase {
    config(): Promise<EnvConfig> {
        return this.fetch<EnvConfig>('scan/env');
    }
}

export default EnvService;
