import { RoleCode } from './account';

export enum CollectorField {
    ExpectedResult = 'expected_result',
    ExpectedLocation = 'expected_location',
    ExpectedBrightness = 'expected_brightness',
    DeceptionTypes = 'deception_types',
}

export interface EnvConfig {
    collectorFields: CollectorField[];
}

export const dataCollectorAvailable = (
    role?: RoleCode,
    collectorFields?: CollectorField[],
): boolean => {
    const isDataCollector = role === RoleCode.DataCollector;
    const envHasCollectorFields = !!collectorFields && collectorFields.length > 0;
    return isDataCollector || envHasCollectorFields;
};
