import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGlobalContext } from '../globalContext';
import { dataCollectorAvailable } from '../models/env';
import {
    Endpoint,
    Liveness,
    OperatorField,
    PredictionResponse,
    RequestBody,
    SecureMode,
} from '../models/prediction';
import Config from '../utils/config';
import { buildUrl } from '../utils/url';
import useApi from './useApi';

/**
 * Checks the error provided is an API error (i.e. a validation error).
 */
const isApiError = (e: AxiosError): boolean => {
    // Use 'any' to check for the error fields because we don't need a data type
    // for it. Note that the error returns an 'unknown' entity for
    // 'e.response.data'. We don't need a data type for 'data' in this method
    // because it checks for an 'undefined' value in all the chain.
    const data: any = e.response?.data;
    return data?.error_message && data?.error_code;
};

/**
 * Transforms 'res' in the custom prediction result for the 'endpoint' provided.
 * Use 'any' in 'res' in order to allow mapping of the different sniffer
 * responses.
 */
const getPredictionResult = (
    res: any,
    endpoint: Endpoint,
    error?: AxiosError,
): PredictionResponse | undefined => {
    if (res) {
        switch (endpoint) {
            case Endpoint.Age:
                if (res.age === undefined || res.st_dev === undefined) {
                    break;
                }
                return { age: res.age, stDev: res.st_dev };
            case Endpoint.Antispoofing:
                if (
                    res.prediction === undefined ||
                    !Object.values(Liveness).includes(res.prediction)
                ) {
                    break;
                }
                return { prediction: res.prediction };
            case Endpoint.AgeAntispoofing:
                if (
                    res.age === undefined ||
                    res.age.age === undefined ||
                    res.age.st_dev === undefined ||
                    res.antispoofing === undefined ||
                    res.antispoofing.prediction === undefined ||
                    !Object.values(Liveness).includes(res.antispoofing.prediction)
                ) {
                    break;
                }
                return {
                    prediction: res.antispoofing.prediction,
                    age: res.age.age,
                    stDev: res.age.st_dev,
                };
            case Endpoint.AgeAntispoofingVerify:
                if (
                    res.age === undefined ||
                    res.age.age_check === undefined ||
                    res.antispoofing === undefined ||
                    res.antispoofing.prediction === undefined ||
                    !Object.values(Liveness).includes(res.antispoofing.prediction)
                ) {
                    break;
                }
                return { ageCheck: res.age.age_check, prediction: res.antispoofing.prediction };
            case Endpoint.AgeVerify:
                if (
                    res.age_check === undefined ||
                    !Object.values(Liveness).includes(res.age_check)
                ) {
                    break;
                }
                return { ageCheck: res.age_check };
            case Endpoint.SelfCheckoutAntiSpoofing:
                if (
                    res.age === undefined ||
                    res.age.age === undefined ||
                    res.age.st_dev === undefined ||
                    res.antispoofing === undefined ||
                    res.antispoofing.prediction === undefined ||
                    !Object.values(Liveness).includes(res.antispoofing.prediction)
                ) {
                    break;
                }
                return {
                    prediction: res.antispoofing.prediction,
                    age: res.age.age,
                    stDev: res.age.st_dev,
                };
        }
    }

    // Check for API error (i.e. validation error).
    if (error && isApiError(error)) {
        // The method 'isApiError' already checks that 'error.response.data' has
        // the field 'error_message' and 'error_code'. We can use 'any' here to
        // get those values.
        const data: any = error.response?.data;
        return {
            errorMessage: data?.error_message,
            errorCode: data?.error_code,
        };
    }
};

export const unexpectedErr = new Error('Unexpected response');
const apiBaseUrl = Config.apiUrl.replace('/api', '');

interface State {
    prediction?: PredictionResponse;
    error?: Error;
}

export interface RequestData {
    threshold?: number;
    operator?: OperatorField;
}

/**
 * The hook performs the prediction request for the provided 'endpoint'. It
 * handle the API errors from server side to transform them to a prediction
 * response (i.e. validation error). The 'error' state indicates there is an
 * unhandled error in the request or response.
 */
export default function usePredict(endpoint: Endpoint, secure: SecureMode) {
    const { scanConfiguration, account, envConfig } = useGlobalContext();
    let url = `${endpoint}`;
    if (secure === SecureMode.Secure) {
        url += '?secure=true';
    }
    const apiRes = useApi(buildUrl(apiBaseUrl, url), {
        hideErrorNotification: () => true,
    });
    const { data, call, error } = apiRes;
    const [state, setState] = useState<State>({});
    const [request, setRequest] = useState<RequestData>();

    // Handle valid response (the prediction run).
    useEffect(() => {
        const res = getPredictionResult(data, endpoint, error);
        // Set the error state if the api returns an error that is not handled
        // as a valid prediction result (including validation errors).
        const apiError = !res && error ? unexpectedErr : undefined;
        setState({ prediction: res, error: apiError });
    }, [data, endpoint, error]);

    const headers = useMemo(() => {
        const headers: any = {};
        if (dataCollectorAvailable(account?.role, envConfig?.collectorFields)) {
            if (scanConfiguration.selectedExpectedResult) {
                headers['X-Expected-Result'] = scanConfiguration.selectedExpectedResult;
            }
            if (scanConfiguration.selectedExpectedLocation) {
                headers['X-Expected-Location'] = scanConfiguration.selectedExpectedLocation;
            }
            if (scanConfiguration.selectedExpectedBrightness) {
                headers['X-Expected-Brightness'] = scanConfiguration.selectedExpectedBrightness;
            }
            if (
                scanConfiguration.selectedDeceptionTypes &&
                scanConfiguration.selectedDeceptionTypes.length > 0
            ) {
                headers['X-Deception-Type'] = JSON.stringify(
                    scanConfiguration.selectedDeceptionTypes,
                );
            }
        }
        return headers;
    }, [
        scanConfiguration.selectedExpectedResult,
        scanConfiguration.selectedExpectedLocation,
        scanConfiguration.selectedExpectedBrightness,
        scanConfiguration.selectedDeceptionTypes,
        account?.role,
        envConfig?.collectorFields,
    ]);

    const predict = useCallback(
        (data: RequestBody) => {
            setRequest((prev) => ({ ...prev, threshold: data.threshold, operator: data.operator }));
            setState({});
            call({
                method: 'POST',
                data,
                headers: {
                    ...headers,
                },
            });
        },
        [call, headers],
    );

    return {
        ...state,
        predict,
        abort: apiRes.abort,
        request,
    };
}
