import React, { useContext } from 'react';
import GlobalContext from '../../globalContext';
import { RoleCode } from '../../models/account';
import { CollectorField } from '../../models/env';
import {
    DeceptionType,
    ExpectedBrightness,
    ExpectedLocation,
    ExpectedResult,
} from '../../models/prediction';
import { snakeCaseToText } from '../../utils/strings';
import CheckboxList from './checkboxList';
import { ConfigSubpanelProps } from './ConfigPanel';
import { ConfigSection } from './ConfigSection';
import RadioButtonList, { RadioButtonEntry } from './radioButtonsList';

// Field lists
const expectedResultFieldList = Object.values(ExpectedResult);
const expectedLocationFieldList = Object.values(ExpectedLocation);
const expectedBrightnessFieldList = Object.values(ExpectedBrightness);
const deceptionTypesFieldList = Object.values(DeceptionType);

export const DataCollectorConfigPanel: React.FC<ConfigSubpanelProps> = ({
    config: initialConfig,
    onConfigChange,
}) => {
    const { envConfig, account } = useContext(GlobalContext);

    // Change config methods
    const onExpectedResultChange = (element?: ExpectedResult) =>
        onConfigChange({
            ...initialConfig,
            selectedExpectedResult:
                element === initialConfig.selectedExpectedResult ? undefined : element,
        });
    const onExpectedLocationChange = (element?: ExpectedLocation) =>
        onConfigChange({
            ...initialConfig,
            selectedExpectedLocation:
                element === initialConfig.selectedExpectedLocation ? undefined : element,
        });
    const onExpectedBrightnessChange = (element?: ExpectedBrightness) =>
        onConfigChange({
            ...initialConfig,
            selectedExpectedBrightness:
                element === initialConfig.selectedExpectedBrightness ? undefined : element,
        });
    const onDeceptionTypesChange = (elements: DeceptionType[]) =>
        onConfigChange({
            ...initialConfig,
            selectedDeceptionTypes: elements,
        });

    // Radio buttons lists
    const expectedResultRadioButtonList: RadioButtonEntry<ExpectedResult>[] = expectedResultFieldList.map(
        (field) => {
            let labelSplitted = (field.charAt(0).toUpperCase() + field.slice(1)).split('_');
            let label = `${labelSplitted[0]} ${labelSplitted[1] ? labelSplitted[1] : ''}`;
            return { value: field, label: label };
        },
    );
    const expectedLocationRadioButtonList: RadioButtonEntry<ExpectedLocation>[] = expectedLocationFieldList.map(
        (field) => {
            return { value: field, label: field.charAt(0).toUpperCase() + field.slice(1) };
        },
    );
    const expectedBrightnessRadioButtonList: RadioButtonEntry<ExpectedBrightness>[] = expectedBrightnessFieldList.map(
        (field) => {
            return { value: field, label: field.charAt(0).toUpperCase() + field.slice(1) };
        },
    );

    const isDataCollector = !!account && account.role === RoleCode.DataCollector;
    const isFieldAvailable = (field: CollectorField) => {
        return !!envConfig && envConfig.collectorFields.indexOf(field) >= 0;
    };

    return (
        <>
            {(isDataCollector || isFieldAvailable(CollectorField.ExpectedResult)) && (
                <ConfigSection title="Expected result">
                    <RadioButtonList
                        list={expectedResultRadioButtonList}
                        selectedValue={initialConfig.selectedExpectedResult}
                        onChange={onExpectedResultChange}
                    />
                </ConfigSection>
            )}
            {(isDataCollector || isFieldAvailable(CollectorField.ExpectedLocation)) && (
                <ConfigSection title="Expected location">
                    <RadioButtonList
                        list={expectedLocationRadioButtonList}
                        selectedValue={initialConfig.selectedExpectedLocation}
                        onChange={onExpectedLocationChange}
                    />
                </ConfigSection>
            )}
            {(isDataCollector || isFieldAvailable(CollectorField.ExpectedBrightness)) && (
                <ConfigSection title="Expected brightness">
                    <RadioButtonList
                        list={expectedBrightnessRadioButtonList}
                        selectedValue={initialConfig.selectedExpectedBrightness}
                        onChange={onExpectedBrightnessChange}
                    />
                </ConfigSection>
            )}
            {(isDataCollector || isFieldAvailable(CollectorField.DeceptionTypes)) && (
                <ConfigSection title="Deception types">
                    <CheckboxList
                        list={deceptionTypesFieldList.map((t) => ({
                            label: snakeCaseToText(t),
                            value: t,
                        }))}
                        value={initialConfig.selectedDeceptionTypes}
                        onChange={onDeceptionTypesChange}
                    />
                </ConfigSection>
            )}
        </>
    );
};
